const resources = [
  {
    name: 'company',
    list: '/company',
    edit: '/company/edit/:id',
  },
  {
    meta: {
      label: 'Offers moderation',
    },
    name: 'offer-moderation',
    list: '/offers-moderation',
    show: '/offers-moderation/show/:id',
    create: '/offers-moderation/create',
    edit: '/offers-moderation/edit/:id',
  },
  {
    meta: {
      label: 'Offers Recruiters',
    },
    name: 'offer-recruiters',
    list: '/offers-recruiters',
    show: '/offers-recruiters/show/:id',
    create: '/offers-recruiters/create',
    edit: '/offers-recruiters/edit/:id',
  },
  {
    name: 'offer',
    meta: {
      label: 'Moderated offers',
    },
    list: '/offer',
    edit: '/offer/edit/:id',
  },
  {
    name: 'sponsored-offers',
    meta: {
      label: 'Sponsored offers',
    },
    list: '/sponsored-offers',
    edit: '/sponsored-offers/edit/:id',
  },
  {
    meta: {
      route: 'raw-offers',
    },
    name: 'raw-offers',
    list: '/raw-offers',
  },
  {
    meta: {
      hide: true,
    },
    name: 'company-users',
    list: '/company-users',
    create: '/company-users/create',
    show: '/company-users/:id',
  },
  {
    meta: {
      label: 'Company track',
    },
    name: 'company-track',
    list: '/company-track',
    show: '/company-track/show/:id',
  },
  {
    meta: {
      hide: true,
    },
    name: 'offer_stats_group',
    list: '/offer_stats_group',
  },
  {
    name: 'super-admin',
    meta: {
      hide: true,
    },
  },
  {
    meta: {
      label: 'Users',
    },
    name: 'user',
    list: '/users',
    show: '/users/show/:id',
  },
  {
    meta: {
      label: 'Review certifications',
    },
    name: 'certifications',
    list: '/certifications',
  },
  {
    meta: {
      label: 'Priority access',
    },
    name: 'priority-access',
    list: '/priority-access',
  },
  {
    meta: {
      label: 'Match review',
    },
    name: 'match-review-company',
    list: '/match-review',
  },
  {
    meta: {
      label: 'Match review - Offer',
      hide: true,
    },
    name: 'match-review-offer',
    list: '/match-review/offer',
  },
  {
    meta: {
      label: 'Match review - User',
      hide: true,
    },
    name: 'match-review-user',
    list: '/match-review/user',
  },
  {
    meta: {
      label: 'Prospect Match review',
    },
    name: 'match-review-prospect-company',
    list: '/prospect-match',
  },
  {
    meta: {
      label: 'Prospect Match review',
      hide: true,
    },
    name: 'match-review-prospect-offer',
    list: '/prospect-match/offer',
  },
  {
    name: 'reported-jobs',
    list: '/reported-jobs',
    meta: {
      label: 'Reported Jobs',
    },
  },
];
export default resources;
